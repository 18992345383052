import styled from '@emotion/styled';
import {
  BalanceValue,
  JackPot,
  BigWheelBarberPole,
  BigWheelStripe,
} from 'assets/images';
import { dimentions, sizes } from 'service';
import { mixins } from 'theme';

export const GameLayoutContainer = styled.div`
  position: relative;
  min-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;

  @media only screen and (orientation: landscape) and (max-width: 667px),
    (max-height: 540px) {
    .guide-left-payout {
      .modal-paytable {
        max-width: 289px;
        max-height: 662px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .modal-guide {
        transform: scale(0.62);
      }
    }
  }

  @media only screen and (orientation: landscape) and (max-width: 667px),
    (max-height: 540px) {
    .guide-right-payout {
      .modal-paytable {
        max-width: 289px;
        max-height: 347px;
        & > div {
          min-width: 408px !important;
          font-size: 14px;
          transform: scale(0.6);
        }
      }
    }
  }

  @media only screen and (orientation: portrait) and (max-width: 375px),
    (max-height: 540px) {
    .guide-right-payout {
      .modal-paytable {
        & > div {
          min-width: 418px;
          transform: scale(0.5);
        }
      }
    }
  }

  @media only screen and (orientation: landscape) and (max-width: 667px),
    (max-height: 540px) {
    .guide-right-payout {
      .modal-paytable {
        & > div {
          width: 418px;
          transform: scale(0.5);
          position: relative;
        }
      }
    }
  }

  @media only screen and (orientation: landscape) and (max-height: 350px) {
    .guide-right-payout {
      .modal-paytable {
        max-width: 189px;
        max-height: 230px;
        & > div {
          transform: scale(0.3);
        }
      }
    }
    .guide-left-payout {
      .modal-guide {
        transform: scale(0.4);
      }
    }
  }

  .toggle-container {
    position: relative;
    top: 26px;
    display: flex;
    justify-content: space-around;
    transform: scale(0.9);
    width: 340px;
  }

  .barber-pole {
    position: absolute;
    z-index: 3;
    height: 604px;
    width: 44px;
    background-image: url(${BigWheelBarberPole});
    background-repeat: no-repeat;
    background-size: 100%;
    top: -1px;
    left: -1px;

    .stripe-container {
      overflow: hidden;
    }
    .stripe {
      background-image: url(${BigWheelStripe});
      background-repeat: no-repeat;
      background-size: 100%;
      height: 526px;
      width: 28px;
      top: 31px;
      position: relative;
      animation: scrollup 20s ease-in infinite;
    }
    &.right-side {
      left: 521px;
    }
  }

  @keyframes scrollup {
    1% {
      background-position-y: 1%;
    }
    100% {
      background-position-y: 100%;
    }
  }

  .bet {
    min-width: 516px !important;
  }

  .balance-value {
    height: 33px !important;
  }

  .bet-value {
    max-width: 163px;
    height: 33px !important;
  }

  #bgVideo {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
  .header-menu {
    position: relative;
    width: 7px;
    height: 7px;
    background: #fff;
    border-radius: 50%;
    top: -9px;
    cursor: pointer;
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      background-color: inherit;
      border-radius: inherit;
    }

    &:before {
      top: 10px;
    }

    &:after {
      top: 20px;
    }
    .ant-image-img {
      z-index: 1;
    }
  }

  .jackpot-wrapper {
    ${(prop) =>
      prop.elevateJackpotWrapper
        ? `
      z-index: 5;
    `
        : `
      z-index: 1;
    `};
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .free-star-left-wrapper {
      position: absolute;
      left: 0px;
      top: -20px;
      .free-star-left {
        position: relative;
        .free-star-image {
          width: 85px;
          height: 82px;
          background-size: contain;
          background-repeat: no-repeat;
        }
        .free-left-value {
          position: absolute;
          border-radius: 6px;
          background-color: #fd3435;
          top: -2px;
          right: 0;
          width: 19px;
          height: 18px;
          text-align: center;
          color: white;
          font-size: 12px;
        }
      }
    }
    .free-celebration-wrapper {
      position: absolute;
      left: 35px;
    }
  }
  .rolling-container {
    user-select: none;
    position: relative;
    max-width: 516px;
    min-width: 516px;
    width: 516px;
    min-height: 830px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* .pitch-outro {
      position: relative;
      z-index: 2;
    } */
    .instruction-one {
      margin-top: 153px;
      @media only screen and (max-width: 430px) {
        bottom: 207px;
      }
    }
    .ins-container {
      position: absolute;
      width: calc(100% + 37px);
      height: 100%;
      background: rgba(0, 0, 0, 0.9);
      display: flex;
      justify-content: center;
      flex-direction: column;
      z-index: 5;
      top: 0;

      @media only screen and (min-width: 430px) {
        min-height: 100vh;
      }
      .ins-wrapper {
        position: relative;
        width: calc(100% - 30px);
        height: 100%;
        .instruction-message {
          position: relative;
          top: -41px;
          /* left: 4px; */
          top: -46px;
          left: 28px;
          width: 86%;
        }
        .fifteen-rolls,
        .consecutive-seven {
          font-size: 17px;
          padding-bottom: 14px;
        }
        @media screen and (max-height: 640px), screen and (max-width: 430px) {
          .guide-screen-left {
            .sidebet-label {
              font-size: 19px !important;
            }
            .fifteen-rolls {
              width: 102px;
            }
            .fifteen-rolls,
            .consecutive-seven {
              font-size: 24px;
              padding-bottom: 14px;
            }
            .align-middle {
              width: 353px !important;
            }
            .modal-paytable {
              & > div {
                margin-top: -8px !important;
              }
            }
            thead {
              tr {
                td {
                  font-size: 24px !important;
                }
                th {
                  font-size: 27px !important;
                  div {
                    span {
                      font-size: 21px !important;
                    }
                  }
                }
              }
            }
            .fifteen-rolls {
            }
            .sidebet {
              font-size: 27px !important;
            }
          }
        }

        .cricket-paytable {
          min-width: 640px;
          min-height: 788px;
          top: 6rem;

          left: -61px;
          @media only screen and (min-width: 430px) {
            left: -75px;
            min-height: 760px;
          }
          background-size: 100% 100%;
          transform: scale(0.83);
          tbody {
            text-align: left;
          }
        }
        .btn-guide {
          z-index: 1;
          cursor: pointer;
          transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1),
            transform 0.45s cubic-bezier(0.25, 1, 0.33, 1),
            border-color 0.45s cubic-bezier(0.25, 1, 0.33, 1),
            color 0.45s cubic-bezier(0.25, 1, 0.33, 1),
            background-color 0.45s cubic-bezier(0.25, 1, 0.33, 1),
            box-shadow 0.45s cubic-bezier(0.25, 1, 0.33, 1);
          &:hover {
            transform: translateY(-3px);
          }
          &.guide2 {
            top: 150px;
          }
          &.guide3 {
            top: 127px;
            left: 41px;
          }
          &.guide5 {
            top: 140px;
            left: 46px;
          }
        }
      }

      .btn-guide.guide3 {
        position: static;
        margin-top: 140px;
      }
    }

    .rolling-header {
      width: 90%;
      ${(prop) =>
        prop.elevateJackpotWrapper
          ? `
        z-index: 6;
      `
          : `
        z-index: 2;
      `};
      height: 85px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .game-id {
        position: absolute;
        right: 0;
        top: 5px;
        font-family: Roboto;
        font-size: 11px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: rgba(255, 255, 255, 0.7);
      }
      .balance-value {
        flex: 3;
        height: 30px;
        background: url(${BalanceValue});
        background-size: 100% 100%;
        margin-left: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-left: 10px;
        span {
          flex: 1;
          text-align: center;
          font-family: Roboto;
          font-size: 18px !important;
        }
        .balance-value-title {
          font-size: 18px;
          color: #e1b353;
          font-weight: 700;
          font-family: 'Roboto';
          filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
        }
      }
      .bet-value {
        flex: 3;
        height: 30px;
        background: url(${BalanceValue});
        background-size: 100% 100%;
        margin-left: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-left: 10px;
        span {
          flex: 1;
          text-align: center;
          font-size: 18px !important;
        }
        .bet-value-title {
          font-size: 18px;
          color: #e1b353;
          font-weight: 700;
          font-family: 'Roboto';
          filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
        }
      }
      .game-number-container {
        height: 100%;
        display: flex;
        align-items: flex-end;
      }
      i {
        position: absolute;
        left: 0;
        top: 10px;
      }
    }
    .score-container {
      width: 100%;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-top: 5px;
      .left-side {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &.play {
          flex: 1;
        }
        .jack-bot {
          height: 70px;
          width: 100%;
          background: url(${JackPot});
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
      }
      .right-side {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        padding-left: 10px;
      }
    }
    .round-bonus {
      position: absolute;
      width: 118px;
      text-align: center;
      top: 159px;
      right: -22px;
    }
    .bet-text-animation {
      position: absolute;
      top: ${dimentions.additionalTop}px;
      font-size: 40px;
      padding: 0 33px;
      color: #ffff45;
      background: linear-gradient(
        360deg,
        #fbe741 25%,
        #dba323 53%,
        #fbe741 72%
      );
      -webkit-text-fill-color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      font-weight: 900;
      font-family: 'Roboto';
      text-align: center;
      filter: drop-shadow(0px 10px 10px rgba(0, 71, 147, 0.5));
      text-transform: uppercase;
      z-index: 1001;
    }
    .bottom-guide-container {
      position: absolute;
      bottom: 40px;
      right: 0;
    }
  }

  @media (max-width: ${mixins.mobileWidth}px),
    (max-height: ${mixins.ipadHeight}px) {
    .cricket-paytable {
      margin-right: 0;
      max-height: 662px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    padding-bottom: 0px;
    .rolling-container {
      padding-top: 10px;
      .rolling-header {
        .game-id {
          top: 5px;
        }
        .jack-bot {
          width: 160px;
          span {
            font-size: 16px !important;
          }
        }
        .coin {
          width: 155px;
          height: 55px;
          background-size: 100% 100%;
        }
        .game-number-container {
          .game-number {
            font-size: 9px !important;
          }
        }
      }
      .score-container {
        width: ${sizes.mainWidthM}px;
        height: 50px;
        margin-top: 0px;
        .jack-bot {
          width: ${sizes.mainWidthM}px;
          background-size: 100% 100%;
          span {
            font-size: 20px !important;
          }
        }
        .scores {
          .score-card {
            width: 120px;
          }
        }
        .left-side {
          .coin {
            width: 160px;
          }
        }
      }

      .bet-text-animation {
        font-size: 22px !important;
      }
    }
  }
  align-items: center;
`;

export const StrikeAnimationContainer = styled.div`
  position: absolute;
  top: 480px;
  z-index: ${(props) => (props.show777 ? 1 : 0)};
  display: ${(props) => (props.show777 ? 'block' : 'none')};
  transform: translateX(-45px);
`;

export const LineWinAnimationContainer = styled.div`
  ${(props) =>
    !props.show
      ? `
    width: 0;
    height: 0;
    opacity: 0;
  `
      : ''}
`;

export const JackpotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .full-card {
    width: 300px;
    position: absolute;
    margin-top: -55px;
  }

  .jack-bot {
    margin-top: 25px;
  }
`;

export const BottomContents = styled.div`
  position: relative;
  width: 98%;
  max-width: 460px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: -210px;
  height: 150px;
  top: 50%;
`;

export const SideBetContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  &::nth-child(2) {
    justify-content: end;
  }
`;

export const BackgroundImage = styled.img`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;

export const HeaderWrapper = styled.div`
  flex: 1;
  display: flex;
`;

export const JackpotText = styled.div`
  position: absolute;
  top: 15px;
  font-family: 'Carnevalee Freakshow' !important;
  font-size: 27px;
  letter-spacing: 4px;
  color: #fff;
  text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
`;

export const BgContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
`;
